
import Vue from "@/interfaces/vue";
import { Component } from "vue-property-decorator";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import { HcaptchaElement } from "@/interfaces/misc";
import i18n from "@/i18n";
const pageKey = "signup";

@Component({
  components: {
    VueHcaptcha,
  },
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class SignUp extends Vue {
  email = "";
  password = "";
  password2 = "";
  firstName = "";
  lastName = "";
  phoneNumber = "";
  accept = false;
  error?: string;
  sitekey = process.env.VUE_APP_SITE_KEY;

  get isDataFilled(){
    return  this.email.trim()!=="" &&
      this.password.trim()!=="" &&
      this.password2.trim()!=="" &&
      this.firstName.trim()!=="" &&
      this.lastName.trim()!=="" &&
      this.phoneNumber.trim()!=="" &&
      /\S+@\S+\.\S+/.test(this.email) &&
      this.accept
  }

  async signup(captcha_token: string) {
    try {
      if (this.isDataFilled) {
        await this.$store.dispatch("signup", {
          email: this.email,
          password: this.password,
          first_name: this.firstName,
          last_name: this.lastName,
          phone_number: this.phoneNumber,
          captcha_token,
        });

        this.$openModal({ component: "AfterSignUpModal" });
      } else {
        this.$toasted.show(this.$t("signup.fill-all") as string);
      }
    } catch (err) {
      Object.values<string[]>(err.response.data).forEach((err) => {
        err.forEach((err: string) => this.$toasted.show(err));
      });
    }
  }

  validate() {
    (this.$refs.hcaptcha as HcaptchaElement).execute();
  }

  onCaptchaExpired() {
    (this.$refs.hcaptcha as HcaptchaElement).reset();
  }
}
